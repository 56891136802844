import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation"
import { FC, HTMLAttributes } from "react"
import { SortObject, useGenericReport } from "../generic-report"
import { 
    Reports, 
    SortDirection, 
    DeviceTypeReportQueryDocument,
    DeviceTypeReportDownloadDocument,
    DeviceTypeReportDownloadQuery,
    DeviceTypeReportQueryQueryVariables,
    DeviceTypeReportQueryQuery
} from "$typings/graphql-codegen"
import { ColumnDefinition } from "../column-definition"
import { ColumnWidth } from "../ColumnWidth"
import React from "react"


enum ColumnKey {
    Buid = 'buid',
    ControllerType = "controllerType",
    Count = 'count',
  }

type ReportResult = DeviceTypeReportQueryQuery
type ReportVariables = DeviceTypeReportQueryQueryVariables
type ReportData = DeviceTypeReportQueryQuery["reports"]["deviceType"]["data"][0]
type ReportDownloadResult = DeviceTypeReportDownloadQuery

  const DeviceTypeReport: FC<HTMLAttributes<HTMLElement>> = () => {
    const [t] = useCaseInsensitiveTranslation()
    const Title: string = t('ui_reports_device_type_title')
    const Filename: string = 'DeviceTypeReport'
    
    const DefaultSortSettings: SortObject =  {
      sortColumnKey: ColumnKey.Buid,
      sortDirection: SortDirection.Asc
    }
    
    const ColumnDefinitions: ColumnDefinition<ReportData>[] = [
      new ColumnDefinition<ReportData>(
        t('UI_Reports_Buid'),
        (row: ReportData) => String(row[ColumnKey.Buid]),
        ColumnKey.Buid,
        ColumnWidth.Common.Buid
      ),
      new ColumnDefinition<ReportData>(
        t('UI_Reports_ControllerType'),
        (row: ReportData) => String(row[ColumnKey.ControllerType]),
        ColumnKey.ControllerType,
        ColumnWidth.Generic.Short
      ),
      new ColumnDefinition<ReportData>(
        t('UI_Reports_Count'),
        (row: ReportData) => String(row[ColumnKey.Count]),
        ColumnKey.Count,
        ColumnWidth.Generic.Short
      ),
    ]
    
    const { GenericReport } = useGenericReport<
      ReportResult,
      ReportVariables,
      ReportData,
      ReportDownloadResult
    >()
    
    function createTitle(): JSX.Element {
        return <div>{Title}</div>
      }
    
    function dataSelector(result: ReportResult): ReportData[] {
      return result?.reports?.deviceType?.data as ReportData[] ?? []
    }
    
    function dataCounter(result: ReportResult): number {
      return dataSelector(result).length
    }
    
    function downloadUrlSelector(result: ReportDownloadResult): string {
      return result?.reports?.deviceType?.blobUrl ?? ''
    }
    
    return <GenericReport
      titleCallback={createTitle}
      reportEnum={Reports.DeviceType}
      mainQueryDocument={DeviceTypeReportQueryDocument}
      downloadQueryDocument={DeviceTypeReportDownloadDocument}
      columnDefinitions={ColumnDefinitions}
      dataSelector={dataSelector}
      dataCounter={dataCounter}
      defaultSortSettings={DefaultSortSettings}
      downloadUrlSelector={downloadUrlSelector}
      downloadFilename={Filename} />
  }

  export default DeviceTypeReport