import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";
import DeviceTypeReport from "$pages/reports-react/device-type-report/device-type-report";

class DeviceTypeReportReactWrapper extends AureliaReactReportWrapper {
  constructor() {
    super(DeviceTypeReport);
  }
}

export default DeviceTypeReportReactWrapper
